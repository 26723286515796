// @ts-nocheck
import React, { useCallback, useRef, useState } from "react";
import Button from "../Button";
import Camera from "../Icons/Camera";
import ChevronIcon from "../Icons/ChevronIcon";
import PaginationDots from "../PaginationDots";
import useIsIntersectingWithRoot from "../../hooks/useIsIntersectingWithRoot";
import Lightbox from "../Lightbox";
import styles from "./imageGalleryGrid.module.scss";

export type ImageData = {
  id: number;
  width: number;
  height: number;
  src: string;
};
type Props = {
  images: Array<ImageData>;
  t?: (
    b: string,
    options: {
      [x: string]: string;
    },
    a: string
  ) => string;
  showOneImageView?: boolean;
  isLCP: boolean;
  onClickCallBack?: () => void;
  className?: string;
};
const addHiddenClass = (imageCount, index, showOneImageView) => {
  if (!showOneImageView && imageCount > 3 && index > 2) {
    return styles.hidden;
  }
  return "";
};
type ImageProps = {
  imageCount: number;
  index: number;
  image: ImageData;
  onImageClick: (a: number) => void;
  onButtonClick: () => void;
  onVisible: (a: number) => void;
  root: any;
  t?: (
    b: string,
    options: {
      [x: string]: string;
    },
    a: string
  ) => string;
  showOneImageView: boolean;
  isLCP: boolean;
};
const Image = ({
  imageCount,
  index,
  image,
  onImageClick,
  onButtonClick,
  root,
  onVisible,
  t,
  showOneImageView,
  isLCP
}: ImageProps) => {
  const ref = useRef();
  const onIntersecting = useCallback(() => {
    onVisible(index);
  }, [onVisible, index]);
  useIsIntersectingWithRoot(ref, root, 0.51, onIntersecting);
  return (
    <div
      className={`${styles.aspectRatioBox} ${
        index === 0 && (imageCount === 1 || imageCount >= 3) ? styles.big : ""
      } ${addHiddenClass(imageCount, index, showOneImageView)}`}
      ref={ref}
    >
      <button onClick={() => onImageClick(index)} type="button">
        <img
          src={image.src}
          width={image.width}
          height={image.height}
          className={styles.image}
          alt="Listing"
          loading={isLCP ? "eager" : "lazy"}
        />
      </button>
      {!showOneImageView && index === 0 && imageCount > 3 && (
        <Button
          icon={<Camera size="1x" />}
          className={styles.moreBtn}
          onClick={onButtonClick}
          modifier="white-black"
        >
          <span>
            {t(
              "listingDetails:allPhotos",
              {
                imageCount
              },
              `All ${imageCount} photos`
            )}
          </span>
        </Button>
      )}
    </div>
  );
};
const cypress = "image-gallery-grid";
const ImageGalleryGrid = ({
  images,
  t = (key, options, defaultValue) => defaultValue,
  showOneImageView = false,
  isLCP,
  onClickCallBack,
  className = ""
}: Props) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageCount = images.length;
  const isFirstImage = currentIndex === 0;
  const isLastImage = currentIndex + 1 === imageCount;
  const onVisible = useCallback(
    (index) => {
      setCurrentIndex(index);
    },
    [setCurrentIndex]
  );
  const onImageClick = useCallback(
    (index) => {
      setCurrentIndex(index);
      setIsOpen(true);
      if (onClickCallBack) {
        onClickCallBack();
      }
    },
    [setCurrentIndex, setIsOpen, onClickCallBack]
  );
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const onNavClick = useCallback(
    (direction) => {
      const calculatedIndex = (currentIndex + direction) % imageCount;
      const index = calculatedIndex < 0 ? imageCount - 1 : calculatedIndex;
      ref.current.children[index].scrollIntoView({
        block: "nearest"
      });
    },
    [currentIndex, imageCount]
  );
  return (
    <div className={`${styles.container} ${className}`} data-cy={cypress}>
      <div
        className={`${styles.grid} ${
          !showOneImageView ? styles.desktopGridColumn : styles.desktopImage
        }`}
        ref={ref}
      >
        {images.map((image, index) => (
          <Image
            imageCount={imageCount}
            index={index}
            image={image}
            onImageClick={onImageClick}
            onButtonClick={onOpen}
            root={ref}
            onVisible={onVisible}
            key={image.id}
            t={t}
            showOneImageView={showOneImageView}
            isLCP={isLCP && index === 0}
          />
        ))}
        {showOneImageView && imageCount > 1 && (
          <>
            <Button
              icon={<ChevronIcon direction="left" />}
              onClick={() => onNavClick(-1)}
              className={styles.leftArrow}
              disabled={isFirstImage}
            />
            <Button
              icon={<ChevronIcon direction="right" />}
              onClick={() => onNavClick(1)}
              className={styles.rightArrow}
              disabled={isLastImage}
            />

            <Button
              icon={<Camera size="1x" />}
              className={styles.moreImageBtn}
              onClick={onOpen}
            >
              <span>{`${currentIndex + 1} / ${imageCount}`}</span>
            </Button>
          </>
        )}
      </div>
      {imageCount > 1 && (
        <div className={styles.dotsContainer}>
          <PaginationDots count={imageCount} active={currentIndex} />
        </div>
      )}
      <Lightbox
        onClose={onClose}
        onPrevClick={() => onNavClick(-1)}
        onNextClick={() => onNavClick(1)}
        currentIndex={currentIndex}
        images={images}
        setIndex={(index) => setCurrentIndex(index)}
        isOpen={isOpen}
        portalParent="#portal"
      />
    </div>
  );
};

export default ImageGalleryGrid;
