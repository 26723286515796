// @ts-nocheck
import React, { useState } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography";

import "./starRating.scss";
import LinkButton from "../LinkButton";

type Props = {
  rating: number;
  onClickStar?: (number: any) => void;
  size?: "small" | "large" | "medium";
  numberOfReviews?: number;
  hideCount?: boolean;
  shouldHover?: boolean;
};

type StarProps = {
  className: string;
  onClick: (number) => void;
  width: string;
  onMouseEnter: () => void;
};

const Star = ({ className, onClick, width, onMouseEnter }: StarProps) => {
  return (
    <LinkButton onClick={onClick} onMouseEnter={onMouseEnter}>
      <div className={className}>
        <div style={{ width }} />
      </div>
    </LinkButton>
  );
};

const StarRating = ({
  rating,
  onClickStar = () => {},
  size = "small",
  numberOfReviews = null,
  hideCount = true,
  shouldHover = false
}: Props) => {
  const [userHoverRating, setUserHoverRating] = useState(null);

  const baseClass = "c-star-rating";
  const innerMaxRating = 5;
  const starWidths = [];
  const starClassNames = [];
  const starClass = "c-star-rating__star";

  const ratingValue = userHoverRating || rating;
  // eslint-disable-next-line no-plusplus
  for (let iter = 0; iter < innerMaxRating; iter++) {
    if (ratingValue - iter >= 1) {
      starWidths.push("100%");
      starClassNames.push(`${starClass} ${starClass}--full`);
    } else if (ratingValue - iter > 0 && ratingValue - iter < 1) {
      starWidths.push(`${Math.round((ratingValue - iter) * 100)}%`);
      starClassNames.push(`${starClass} ${starClass}--half`);
    } else {
      starWidths.push("0");
      starClassNames.push(`${starClass} ${starClass}--empty`);
    }
  }
  const title = `Rating: ${ratingValue} / ${innerMaxRating}`;
  const totalReviews = numberOfReviews || 0;
  return (
    <div
      title={title}
      className={bem(baseClass, null, { size })}
      onMouseLeave={() => {
        if (!shouldHover) {
          return;
        }
        setUserHoverRating(rating);
      }}
    >
      {[...Array(5)].map((item, key) => {
        return (
          <Star
            className={starClassNames[key]}
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            width={starWidths[key]}
            onClick={() => {
              onClickStar(key + 1);
            }}
            onMouseEnter={() => {
              if (!shouldHover) {
                return;
              }
              setUserHoverRating(key + 1);
            }}
          />
        );
      })}

      {!hideCount && (
        <Typography
          size={size === "small" ? "subtext" : "primary"}
          className={bem(baseClass, "rating")}
        >
          {`(${totalReviews.toString()})`}
        </Typography>
      )}
    </div>
  );
};

export default StarRating;
