// @ts-nocheck
import React from "react";
import CameraSvg from "../../../svg/camera.svg";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./camera.module.scss";

type Props = {
  size: IconSizes;
};
const Camera = ({ size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <CameraSvg className={`${styles.base} ${sizeClass}`} />;
};
export default Camera;
