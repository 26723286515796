// @ts-nocheck
import { useCallback, useEffect } from "react";

const useKeyPress = (keyCode, onKeyCode) => {
  const onKeyDown = useCallback(
    (event: SyntheticKeyboardEvent<typeof window>) => {
      if (event.key === keyCode && typeof onKeyCode === "function") {
        onKeyCode();
      }
    },
    [onKeyCode, keyCode]
  );
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);
};
export default useKeyPress;
