// @ts-nocheck
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { createPortal } from "react-dom";
import useModalHook from "../../hooks/useModalHook";
import Button from "../Button";
import ChevronIcon from "../Icons/ChevronIcon";
import CrossIcon from "../Icons/CrossIcon";
import Camera from "../Icons/Camera";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";
import useIsIntersectingWithRoot from "../../hooks/useIsIntersectingWithRoot";
import useEscapeKeyWithOnCloseCallback from "../../hooks/useEscapeKeyWithOnCloseCallback";
import useKeyPress from "../../hooks/useKeyPress";
import styles from "./lightbox.module.scss";

type ImageData = {
  id: number;
  src: string;
  width: number;
  height: number;
};
type Props = {
  isOpen: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  portalParent?: string;
  onClose: () => void;
  images: Array<ImageData>;
  currentIndex: number;
  setIndex: (index: number) => void;
};
type MobileProps = {
  images: Array<ImageData>;
  imageCount: number;
  onClose: () => void;
};
type ImageProps = {
  index: number;
  image: ImageData;
  onVisible: (a: number) => void;
  root: any;
};
type DesktopProps = {
  onClose: () => void;
  currentIndex: number;
  onClickPrev: () => void;
  onClickNext: () => void;
  imageCount: number;
  activeImg: ImageData | undefined | null;
};
const cypress = "lightbox";
const Image = ({ index, image, root, onVisible }: ImageProps) => {
  const ref = useRef();
  const onIntersecting = useCallback(() => {
    onVisible(index);
  }, [onVisible, index]);
  useIsIntersectingWithRoot(ref, root, 0.51, onIntersecting);
  return (
    <div className={styles.imageBox} key={image.id} ref={ref}>
      <img
        src={image.src}
        width={image.width}
        height={image.height}
        className={styles.smImage}
        alt="Listing"
        loading="lazy"
      />
    </div>
  );
};
const MobileView = ({ images, imageCount, onClose }: MobileProps) => {
  const ref = useRef();
  const [active, setActive] = useState(0);
  const onVisible = useCallback(
    (index) => {
      setActive(index);
    },
    [setActive]
  );
  return (
    <div className={styles.lightbox} data-cy={cypress} ref={ref}>
      <div className={styles.smActionContainer}>
        <button
          type="button"
          className={`${styles.closeBtn} ${styles.closeBtnSm}`}
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <span className={styles.countSm}>
          {`${active + 1} / ${imageCount}`}
        </span>
      </div>
      <div className={styles.grid}>
        {images.map((image, index) => (
          <div className={styles.swipeItem} key={`${image.id}-${image.src}`}>
            <Image
              index={index}
              image={image}
              root={ref}
              onVisible={onVisible}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
const DesktopView = ({
  onClose,
  currentIndex,
  onClickNext,
  onClickPrev,
  imageCount,
  activeImg
}: DesktopProps) => {
  useKeyPress("ArrowLeft", onClickPrev);
  useKeyPress("ArrowRight", onClickNext);
  return (
    <div className={styles.lightbox} data-cy={cypress}>
      <div className={styles.inner}>
        <div className={styles.container}>
          <button
            type="button"
            className={`${styles.closeBtn} ${styles.closeBtnLg}`}
            onClick={onClose}
          >
            <CrossIcon />
          </button>
          <Button
            icon={<ChevronIcon direction="left" />}
            onClick={onClickPrev}
            modifier="white-black"
            className={styles.leftArrow}
            cypress={`${cypress}-left-arrow`}
          />
          <div className={styles.imageContainer}>
            <img
              src={activeImg?.src}
              width={activeImg?.width}
              height={activeImg?.height}
              className={styles.image}
              alt="Listing"
              loading="lazy"
            />
            <span className={styles.countDesktop}>
              <Camera size="1x" />
              {`${currentIndex + 1} / ${imageCount}`}
            </span>
          </div>
          <Button
            icon={<ChevronIcon direction="right" />}
            onClick={onClickNext}
            modifier="white-black"
            className={styles.rightArrow}
            cypress={`${cypress}-right-arrow`}
          />
        </div>
      </div>
    </div>
  );
};
const Lightbox = ({
  isOpen,
  onPrevClick,
  onNextClick,
  portalParent = process.env.NODE_ENV === "test" ? "body" : "#__next",
  onClose,
  currentIndex,
  images,
  setIndex
}: Props) => {
  const [portalInit, setPortalInit] = useState(false);
  const ref = useRef(null);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const isDesktop = useMatchMediaMatches("(min-width: 640px)") === true;
  useModalHook(isOpen, "modal-open");
  useEscapeKeyWithOnCloseCallback(onCloseRef, isOpen, true);
  useEffect(() => {
    ref.current = document.querySelector(portalParent);
    if (ref.current) {
      setPortalInit(true);
    }
  }, [portalParent, setPortalInit]);
  const imageCount = images.length;
  const activeImg = useMemo(() => {
    return images[currentIndex || 0];
  }, [currentIndex, images]);

  // useful for mobile view. This will enable the selected image always the first item when opened on mobile
  const sortedArray = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    () => [...images].sort((a, _) => (a.id === activeImg?.id ? -1 : 0)),
    [images, activeImg]
  );
  const onClickPrev = useCallback(() => {
    const index = currentIndex === 0 ? imageCount - 1 : currentIndex - 1;
    setIndex(index);
    onPrevClick();
  }, [currentIndex, imageCount, setIndex, onPrevClick]);
  const onClickNext = useCallback(() => {
    const index = currentIndex === imageCount - 1 ? 0 : currentIndex + 1;
    setIndex(index);
    onNextClick();
  }, [currentIndex, imageCount, setIndex, onNextClick]);
  if (portalInit) {
    return createPortal(
      <>
        {isOpen ? (
          <>
            {isDesktop ? (
              <DesktopView
                onClose={onClose}
                currentIndex={currentIndex}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                imageCount={imageCount}
                activeImg={activeImg}
              />
            ) : (
              <MobileView
                images={sortedArray}
                imageCount={imageCount}
                onClose={onClose}
              />
            )}
          </>
        ) : null}
      </>,
      ref.current
    );
  }
  return null;
};

export default Lightbox;
